$(function() {
  if (
    !(Util.controller_is('careers') && Util.action_is('new')) &&
    !(Util.controller_is('careers') && Util.action_is('create'))
  ) {
    return false;
  }

  $('select.js-term-date').wrap('<div class="mr-2"></div>');
});
