import 'jquery-match-height';

$(function() {
  if (!(Util.controller_is('top'))) {
    return false;
  }

  $('a[href^="#"]').click(function() {
    const speed = 400;
    const href = $(this).attr('href');
    const target = $(href === '#' || href === '' ? 'html' : href);
    const position = target.offset().top;
    $('body, html').animate({ scrollTop:position }, speed, 'swing');
    return false;
  });
  $('.special__card').matchHeight();
});
