/*
Template Name: Ubold - Responsive Bootstrap 4 Admin Dashboard
Author: CoderThemes
Version: 3.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: File uploads init js
*/

var drEvent = $('.dropify').dropify({
    messages: {
        'default': '画像をドラッグするかクリックしてください',
        'replace': '画像をドラッグするかクリックしてください',
        'remove': '削除',
        'error': '画像を追加できませんでした'
    },
    error: {
        'fileSize': 'ファイルサイズが大き過ぎます (1M max).'
    }
});

drEvent.on('dropify.beforeClear', function(event, element){
    var result = confirm("削除しますか？");
    if (result) {
      $('input.remove').prop("checked",true);
    } else {
      return false;
    }
});