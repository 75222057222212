
$(function() {
    if (!(Util.controller_is('projects') && Util.action_is('index'))) { return false; }
    // ファイル名を表示
    $('.custom-file-input').on('change',function(){
        $(this).next('.custom-file-label').html($(this)[0].files[0].name);
    })
    // ファイル名をクリア
    $('.inputFileCancel').on('click',function(){
        $('.custom-file-label').html('ファイルを選択');
        $('.custom-file-input').val('');
    })
})
