// Entry point for the build script in your package.json
// 後で動くようにしたい
import Rails from '@rails/ujs';
Rails.start();
// import "@hotwired/turbo-rails"
import "./controllers"

import './common/jquery';
import "@fortawesome/fontawesome-free/js/all.js"
// import '@fortawesome/fontawesome-free/js/all';
// import '@fortawesome/fontawesome-free/js/solid';

import 'jquery-validation';
import 'jquery-validation/dist/localization/messages_ja.min';
import 'bootstrap';
import 'jquery.counterup';
import 'matchheight';
import 'cleave.js';
import 'dropify/dist/js/dropify.min';
import '../plugins/dropify/dropify'

import './common/form-fileuploads.init';
// common
import './common/util';

// front
import './front/common/app';
import './front/common/common';
import './front/top/index';
import './front/project/index';
import './front/mypage/user/new';

import './front/mypage/mypage';
import './front/mypage/dashboard';
import './front/mypage/user/edit';
import './front/mypage/skill/edit';
import './front/mypage/career/new';
import './front/mypage/career/edit';

import './front/company_mypage/company/new';
import './front/company_mypage/company/edit';
import './front/media/index';
import './front/contact/new';

import './front/company_mypage/message/show';
import './front/agent_mypage/message/show';
import './front/mypage/user_message/show';
import './front/company_mypage/project/index';
import './front/company_mypage/project/edit';
import './front/company_mypage/project/new';
