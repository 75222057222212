$(function() {
  setTimeout(function(){
    $(".js-notification").fadeOut();
  }, 7000);

  $('.js-notification-close, .notification').on('click',function(){
    $(".js-notification").fadeOut();
  });

  var windowWidth = $(window).width();
  var headerHight = 100;
  $(window).on('load', function() {
  var speed = 1000;
  var urlHash = location.hash;
  var target = $(urlHash == "#" || urlHash == "" ? 'html' : urlHash);
  var position = target.offset().top-headerHight;
  $('body,html').scrollTop(position)
  return false;
 });
});
$(function() {
  $('tr[data-href]').click(function(e) {
    if($(e.target).is('a') || $(e.target).is('input')){
       return;
    } else {
      window.location = $(e.target).closest('tr').data('href');
    }
  });
});

//====================
//チェックボックス必須化
//====================
$(function() {
  $(".checkRequired").each(function () {
    var defaultCheckboxes = $(this).find(':checkbox[required]');
    var isChecked = defaultCheckboxes.is(':checked');
    defaultCheckboxes.prop('required', !isChecked);
  });

  var requiredCheckboxes = $(':checkbox[required]');
  requiredCheckboxes.on('change', function(e) {
    var checkboxGroup = requiredCheckboxes.filter('[name="' + $(this).attr('name') + '"]');
    var isChecked = checkboxGroup.is(':checked');
    checkboxGroup.prop('required', !isChecked);
  });
});

$(function() {
  $('button.header__hamburgerButton').on('click', function (){
    $('.sideBar').fadeToggle();
  });
});