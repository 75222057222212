import iziModal from 'izimodal/js/iziModal.js';
$.fn.iziModal = iziModal;

$(function() {
  if (
    !(Util.controller_is('members') && Util.action_is('new')) &&
    !(Util.controller_is('members') && Util.action_is('create'))
  ) {
    return false;
  }

  const $inputBirthday = $('.entryForm__inputs--birthday select');
  $inputBirthday.wrap('<div class="entryForm__selectContainer entryForm__selectContainer--third"><div class="entryForm__selectBox"></div></div>');

  const $form = $('form#new_member');
  $form.validate({
    errorPlacement: function(error, element) {
      error.insertAfter(
        $(element).parents('.form-group'));
    },
    focusInvalid: false,
    invalidHandler: function(form, validator) {
      if (!validator.numberOfInvalids()) {
        return;
      }
      $('html, body').animate({
        scrollTop: $(validator.errorList[0].element).offset().top
      }, 500);
    },
      errorClass: "is-invalid text-danger",
      errorElement: "div"
  });
  $.extend($.validator.messages, {
    required: 'この項目は必須です。',
  });

  const $entryFormModal = $('.entryFormModal');
  $entryFormModal.iziModal({
    headerColor: '#23334B',
    closeButton: true,
    radius: 3,
    borderBottom: false,
    padding: 0
  });

  $('.js-open-modal').click(function(e){
    e.preventDefault();
    if(!$form.valid()) {
      return false;
    }

    [...Array(4)].map(function(_e, index) {
      const num = index + 1;
      const fieldName = 'emailField';
      const $filed = $('input.' + fieldName);
      let val = $filed.val();


      $('.entryFormModal__dd.' + fieldName).html(val);
    });

    $entryFormModal.iziModal('open');
  });

  $('.js-submit').click(function(){
    $form.submit();
  });
});
