$(function() {
  $('.mypage__row .dashBoard__card').matchHeight();


  $(".js-headerMenuBtn").on('click', function(){
    $(this).toggleClass('active');
    if($(this).hasClass('active')){
      $(".js-headerModal").fadeIn();
    }else{
      $(".js-headerModal").fadeOut();
    }
  });
});