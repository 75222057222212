import Select2 from 'select2';
$(function () {

  if (
    !(Util.controller_is('careers') && Util.action_is('new')) &&
    !(Util.controller_is('careers') && Util.action_is('edit')) &&
    !(Util.controller_is('careers') && Util.action_is('update'))
  ) {
    return false;
  }
  $('.skillAdd').on('click', () => {
    // 保存済み存スキル数抽出　→parseInt(数字に変換)、last(最後の要素取得)、attr(属性指定)、replace(数字だけ抽出して置き換え)
    let strength_id = parseInt($('.strengths_skill').last().attr('id').replace(/[^0-9]/g, ''), 10)
    strength_id++

    // 得意スキルは10個まで登録可能とする
    if (strength_id >= 10) {
      $('.skillAdd').remove()
    }

    // 最後の要素をクローン
    $clone = $('.strengths_add').last().clone()

    // IDの変更　※select2はIDやdata-select2-idが重複の場合は後要素が適用されてしまう仕様
    $clone.find('.strengths_skill').attr('id', `skill-id-${strength_id}`)
    $clone.find('.strengths_skill').attr('data-select2-id', `select2-data-skill-id-${strength_id}`)

    // クローン元で選択していた値をクリア
    $clone.find('.strengths_skill option').each((i, e) => {
      if (e.selected) {
        e.selected = false
      }
    })

    // select2再適用するとspanが更に追加されるので事前に削除
    $clone.find("span").remove();

    // 要素追加
    $clone.appendTo('.strengths');

    // select2適用
    $('.select2').select2({})
  })

  Select2()
  $('.select2').select2()

  // 得意スキル未選択の項目は送信しない　
  $('#submit-btn').on('click', () => {
    $('.strengths_skill option').each((i, e) => {
      e.disabled = false
      if (e.value === '') {
        e.disabled = true
      }
    })
    $('form').submit()
  })

});
