const week = ['(Sun)', '(Mon)', '(Tue)', '(Wed)', '(Thu)', '(Fri)', '(Sat)']

$(function () {
    if (!(Util.controller_is('messages') && Util.action_is('index') && Util.spacename_is('company_mypage'))) { return false; }
    $(".message-content").addClass("active");

    $("body").on('click', ".hide-button", function () {
        $(".message-content, .message__form, .message-box__rightclm").removeClass("active");
    })

    $(".message-list").on('click', function (e) {
        e.preventDefault();
        $(".message-list").removeClass("message-list__link--active")
        $(".message-content, .message__form, .message-box__rightclm").addClass("active");

        $.ajax({
            type: "GET",
            url: $(this).data("href"),
            dataType: "json",
            context: this,
        })
            // Ajaxリクエストが成功した場合
            .done(function (data) {
                data.forEach((element, index) => {
                    // 日時データ処理
                    let date = new Date(element.created_at);
                    let created_day = date.getMonth() + 1 + '/' + date.getDate() + week[date.getDay()];
                    let created_time = date.getHours() + ':' + ('0' + date.getMinutes()).slice(-2);
                    // 初回レコード処理
                    if (index === 0) {
                        // ユーザー名表示
                        let userName = 'To: ' + element.last_name + ' ' + element.first_name;
                        $(".message-head").html(`
                          <div class="border-bottom font-weight-bold p-3 mb-2">
                            <button class="hide-button btn btn-secondary mr-2 btn-sm">
                            <i class="fa fa-chevron-left"></i>
                            </button>
                            ${userName}
                          </div>
                        `);
                        $(".message-content").html('')
                        // メッセージ日時表示
                        this.before_created_day = created_day
                        insertDate(created_day)
                        // エントリーメッセージと時刻表示
                        insertEntryMessage(element, created_time)
                        // ２レコード以降の処理
                    } else {
                        // メッセージ日時表示　同一日付は画面出力しない
                        if (this.before_created_day !== created_day) {
                            this.before_created_day = created_day
                            insertDate(created_day)
                        }
                        // メッセージ内容と時刻表示　企業
                        if (element.sender_type === 'Staff') {
                            $(".message-content").append(`
                            <div class="media p-3 col-md-6 ml-auto d-flex align-items-end">
                                <span class="media-date d-block d-flex justify-content-end">
                                ${created_time}
                                </span>
                                <div class="media-message p-3 rounded-lg receiver ml-1">
                                    <span>
                                    ${element.message}
                                    </span>
                                </div>
                            </div>
                            `);
                            // メッセージ内容と時刻表示　Project
                        } else if (element.sender_type === 'Member' && element.project_id !== null) {
                            insertEntryMessage(element, created_time)
                            // メッセージ内容と時刻表示　ユーザー
                        } else {
                            $(".message-content").append(`
                            <div class="media p-3 col-md-6 d-flex align-items-end">
                                <div class="media-message p-3 bg-light rounded-lg mr-1">
                                    <span>
                                    ${element.message}
                                    </span>
                                </div>
                                <span class="media-date d-block">
                                ${created_time}
                                </span>
                            </div>
                            `);
                        }
                    }
                    // 未読バッチ非表示
                    $(this).children(".is-read").remove();
                });
                // エントリー内容承認しないとメッセージを送れないようにする
                if (data[0].enable_message_status === false) {
                    $(".message__form").addClass("disable");
                } else {
                    $(".message__form").removeClass("disable");
                }
                // form_withのf.hidden_fieldのValueにmessage_list_idをセット
                $("input[name='message[message_list_id]']").val(data[0].message_list_id)
                $(".message__form").addClass("active");
            })
            // Ajaxリクエストが失敗した場合
            .fail(function (XMLHttpRequest, textStatus, errorThrown) {
                alert(errorThrown);
            });

        // 選択しているメッセージリストを強調
        const id = $(this).prop("id")
        $("#" + id).addClass("message-list__link--active");
    })


    $(document)
        .on('change keyup keydown paste cut', '.message__text', function () {
            if ($(this).outerHeight() > this.scrollHeight) {
                $(this).height(1)
            }
            while ($(this).outerHeight() < this.scrollHeight) {
                $(this).height($(this).height() + 1)
            }
        });

});

function insertDate(created_day) {
    $(".message-content").append(`
  <div class="d-flex justify-content-center">
  <span class="badge rounded-pill bg-dark text-white p-2 created-day">${created_day}</span>
  </div>`);
}

function insertEntryMessage(element, created_time) {
    $(".message-content").append(`
    <div class="media p-3 col-md-6 d-flex align-items-end">
        <div class="media-message p-3 bg-light rounded-lg mr-1">
            <span>
            <a href="/company_mypage/projects/${element.project_id}" target="_blank" rel="noopener noreferrer">「${element.message}」</a>の応募がありました。
            <a class="badge" href="/company_mypage/projects/${element.project_id}" target="_blank" rel="noopener noreferrer" role="button">案件詳細>></a>
            </span>
        </div>
        <span class="media-date d-block">
        ${created_time}
        </span>
    </div>`);
}
