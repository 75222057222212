$(function() {
  if (
    !(Util.controller_is('members') && Util.action_is('edit')) &&
    !(Util.controller_is('members') && Util.action_is('update'))
  ) {
    return false;
  }
  // bsCustomFileInput.init()
  // $('select.js-birth-date').wrap('<div class="mr-2"></div>');
});
