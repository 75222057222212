$(function() {
  if (
    !(Util.controller_is('projects') && Util.action_is('index'))
  ) {
    return false;
  }

  $('select.js-term-date').wrap('<div class="mr-2"></div>');
  $('#js-submit-skill').on('click', function(){
    var arr = [];
    $('.miniCheckboxes [class="skill_checkbox"]:checkbox:checked').each(function(){
      $(this).prop('disabled', true);
      arr.push($(this).val());
    });

    var obj = {
      skill: arr
    }

    getParamStr = $.param(obj);
    // window.location.href = '/projects?' + getParamStr;
  });
});
